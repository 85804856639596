//changed

import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Seo from "../components/Seo";
import HeaderUnderlined from "../components/HeaderUnderlined";

const Policy = ({ data }) => {
  const { title, body, created } = data.allNodePage.nodes[0];

  return (
    <Layout>
      <Seo title={title} />
      <Container fluid="lg">
        <Row>
          <Col className="p-5 bg-white">
            <HeaderUnderlined titlesrc={title} className="fadein" />
            <div
              dangerouslySetInnerHTML={{
                __html: body.processed,
              }}
            />
            <p className="pt-5">
              Дата публикации и обновления статьи: <b>{created}</b>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const query = graphql`
  {
    allNodePage(filter: { path: { alias: { eq: "/privacy" } } }) {
      nodes {
        id
        title
        body {
          processed
        }
        created(formatString: "DD-MMM-YYYY")
        changed(formatString: "DD-MMM-YYYY")
      }
    }
  }
`;
export default Policy;
